@import url(https://fonts.bunny.net/css?family=merriweather:300);
@tailwind base;
@tailwind components;
@tailwind utilities;

.background {
    background-image: url("./background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.logo {
    position: fixed;
    top: 20px;
    left: 30px;
    width: 114px;
    padding: 16px 0 0 0;
    display: block;
    height: 80px;
    background-image: url("./logo.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.text {
    font-size: 40px;
    font-family: 'Merriweather', serif;
    text-shadow: 0 2px 0 rgba(0,0,0,.6);
}

@media only screen and (max-width: 809px) {
    .text {
        font-size: 26px;
    }
}
